<template>
    <div class="page_writers">
        <section class="info">
            <div class="wrapper">
                <div class="row">
                    <div class="info-content">
                        <p>
                            By completing and submitting the order form you agree to be bound by
                            and are becoming a party to the current agreement.
                        </p>

                        <h3>DELIVERY</h3>

                        <p>
                            The completed order form is the set of directions that will be
                            followed by our writers to provide a digital research/writing
                            service that will be delivered by electronic mail at your e-mail
                            address specified above as well as via direct download off our site.
                            The login information to your secure personal account shall be provided
                            upon validating your billing details. We offer the free direct download
                            feature that allows to download all project files directly from your
                            personalized account (Customer Service Area)
                        </p>

                        <h3>TERMS OF USE</h3>

                        <p>
                            You agree that the written material produced by {{ domain }}
                            represents a model work that should not be submitted for
                            academic credit "as is". You may use the work for further
                            research or may edit it to match your writing style, level
                            and vocabulary. We do not guarantee any specific letter grades
                            or any other form of academic approval and can only guarantee
                            to provide quality work based on the original order description.
                        </p>

                        <h3>DEADLINE</h3>

                        <p>
                            Should we have difficulty meeting your specified deadline, we will contact
                            you prior to the deadline to request an extension, or discuss a
                            discount or other form of compensation suitable to both parties to
                            reach an agreement (partial refund, discount, etc.). While minor delays
                            in delivery do not constitute grounds for a full refund, we will do our
                            best to reach an agreement with the buyer by means of discounts or other
                            forms of partial compensation. Minor delays in delivery do not constitute
                            grounds for a full refund
                        </p>

                        <h3>CONTENT</h3>

                        <p>
                            We shall complete your project based solely on the instructions supplied
                            in the order form. The writer will be responsible for meeting all the
                            instructions. We shall not be responsible for processing additional
                            instructions not included in the original project description. Such
                            requests may still be accomplished as a one time courtesy at the sole
                            discretion of the writer. Minor content problems do not constitute
                            grounds for a full refund
                        </p>

                        <h3>PLAGIARISM</h3>

                        <p>
                            The content is written from scratch by a competent writer and contains
                            no plagiarism. All quotes and other people's ideas shall be properly
                            documented using the specified citation style.
                            It is our guarantee that direct quotes shall not take
                            more than 20% of the content, unless specifically requested
                            by the buyer. Should the buyer find two or more straight sentences
                            (not quotations, at least 20 words total in a straight sequence)
                            copied from another source verbatim WITHOUT PROPER CITATION, we will
                            offer you either a complete rewrite or a refund. Please note that
                            plagiarism reports of Turnitin or similar programs with the overall
                            score of less than 35% do not constitute sufficient grounds for a refund.
                        </p>

                        <h3>REVISIONS/REWRITES</h3>

                        <p>
                            The company guarantees to adequately meet the original requirements
                            set forth by the buyer. Should our writer overlook some guidelines,
                            the buyer will need to file a revision request through the company's
                            site (Support Center) and the writing shall be corrected free of charge,
                            unless the request is beyond the original project description.
                            The revision shall be delivered promptly. We reserve the right to reject
                            the revision requests submitted after 14 days from the moment of completion.
                            If the revision request is beyond the original project description,
                            the buyer will have to submit a new order (EDITING) or contact the company
                            regarding some extra compensation.
                        </p>

                        <h3>CUSTOMER SATISFACTION GUARANTEE</h3>

                        <p>
                            If you are dissatisfied with the service provided by our company,
                            we will have the work revised, corrected or rewritten until
                            all the initial requirements are adequately followed. If there
                            are some minor issues, you can request a revision and have the
                            work corrected promptly and free of charge. The buyer can also
                            request a different writer, if there is a valid concern. The company
                            will revise or rewrite the project free of charge until the work fully
                            meets the original order description. Mere dissatisfaction with the service
                            does not constitute grounds for a full refund.
                        </p>

                        <h3>REFUND POLICY</h3>

                        <p>
                            The company guarantees to adequately meet the original requirements set forth by the buyer. Should our writer overlook some guidelines, the buyer will need to file a revision request through the company's site (Support Center) and the writing shall be corrected free of charge unless the request is beyond the original project description. The revision shall be delivered promptly. We reserve the right to reject the revision requests submitted after 14 days from the moment of completion. If the revision request is beyond the original project description, the buyer will have to submit a new order (EDITING) or contact the company regarding some extra compensation 14 days after completion for 1-10 pages and 30 days after completion for big orders.
                        </p>

                        <h3>CONFIDENTIALITY</h3>

                        <p>
                            We can guarantee that no information related to your using our service will
                            ever be distributed to any third party without your consent. The company
                            guarantees not to reuse or resell the works produced for the clients,
                            and the company's staff writers are bound by the same agreement.
                        </p>

                        <h3>CONTACT AND DISPUTE RESOLUTION</h3>

                        <p>
                            Should you be dissatisfied with the service provided by the company,
                            you agree to always contact the company first for direct
                            resolution. The company should be contacted through Live Chat or by
                            submitting a support ticket through our Support Center. If you have
                            not been able to reach an acceptable solution after 14 days since the
                            time of your first contact via the aforementioned communication channels,
                            you may contact other organizations for assistance. However, failure to
                            contact our company directly before initiating a chargeback shall be
                            considered a breach of contract and shall be disputed accordingly.
                        </p>

                        <p>
                            {{ domain }} is owned and operated by MEDIATECH LIMITED. Company registration number 1242512.
                            Any relevant information should be faxed at 1-866-308-7123, 1-404-963-0617
                            mailed to Office 813, 8/F, 610 Nathan Road, Hollywood Plaza, HK.
                            or emailed at hk@freelancewritingcenter.com
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Terms Of Service'
    },
    computed: {
        domain() {
            return process.env.VUE_APP_DOMAIN
        }
    }
}
</script>
